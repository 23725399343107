import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Box } from "grommet"
import styled from "styled-components"

const FrameContainer = styled(Box)`
  height: 100vh;
  width: 100vw;
  border-radius: 40px;
  overflow: hidden;
`

const Frame = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
`

const ResumePage = () => {
  return (
    <Layout>
      <Seo title="resume" />
      <FrameContainer>
        <Frame src="https://docs.google.com/gview?url=https://github.com/petabite/resume/raw/main/Philip_Zhang_Resume.pdf&embedded=true" />
      </FrameContainer>
    </Layout>
  )
}

export default ResumePage
